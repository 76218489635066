
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Tag, FormInfo } from '../../types/information'
import { Resource } from '@/types/common'

@Component({
  name: 'InformationAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private formData: FormInfo = {
    informationTile: '',
    informationContent: '',
    informationIntroduction: '',
    isShow: 1,
    tagList: [],
    resource: null
  }

  private formRules = {
    informationTile: [
      { required: true, message: '请输入资讯标题', trigger: ['blur'] }
    ],
    informationIntroduction: [
      { required: true, message: '请输入资讯简介', trigger: ['blur'] }
    ],
    resource: [{ required: true, message: '请上传图片', trigger: ['change'] }],
    informationContent: [
      { required: true, message: '请输入内容', trigger: ['blur', 'change'] }
    ]
  }

  private title = '新增'
  private editor: any = null
  private addShow = false
  private tagList: Array<Tag> = []
  private tagName = ''
  private submitShow = false

  created () {
    this.getTagList()
    this.initRichText()

    if (this.$route.params.id) {
      this.title = '编辑'
      this.getDetail()
    }
  }

  // 获取标签
  getTagList () {
    this.$axios.get(this.$apis.common.thinktankTag).then((res) => {
      this.tagList = res.list
    })
  }

  onSuccess (file: Resource) {
    this.formData.resource = file
  }

  // 编辑获取详情
  getDetail () {
    this.$axios.get(this.$apis.information.selectInformationDetail, {
      informationId: this.$route.params.id
    }).then(res => {
      this.formData = res
      this.editor.txt.html(res.informationContent)
      console.log(' this.editor: ', this.editor)
    })
  }

  // 添加标签
  addTag () {
    const index = this.formData.tagList.findIndex((item: Tag) => {
      return item.tagName === this.tagName
    })
    if (index === -1) {
      (this.formData.tagList as Array<Tag>).push({
        tagId: null,
        tagName: this.tagName
      })
      this.tagName = ''
      this.addShow = false
    } else {
      this.$message({
        message: '标签重复',
        type: 'warning'
      })
    }
  }

  // 删除标签
  removeTag (i: number) {
    this.formData.tagList.splice(i, 1)
  }

  // 初始化富文本
  initRichText () {
    this.$nextTick(() => {
      this.editor = initWangEdit('#informationRichText', { placeholder: '请输入资讯内容' })
      this.editor.create()
    })
  }

  queryTag (queryString: string, cb: Function) {
    const tagList = this.tagList
    const results = queryString
      ? tagList.filter(this.createTag(queryString))
      : tagList
    if (results.length === 0) {
      this.addShow = true
    } else {
      this.addShow = false
    }
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createTag (queryString: string) {
    return (tag: Tag) => {
      return tag.tagName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    }
  }

  handleSelectTag (v: Tag) {
    const result = this.formData.tagList.find(
      (item) => item.tagName === v.tagName
    )
    result
      ? this.$message.warning('不能添加重复的标签')
      : this.formData.tagList.length >= 3
        ? this.$message.warning('最多可选三个标签')
        : (this.formData.tagList as Array<Tag>).push(v)
  }

  imgRemove () {
    this.formData.resource = null
  }

  // 保存
  onSubmit () {
    this.formData.informationContent = this.editor.txt.html()
    const url = this.$route.params.id
      ? this.$apis.information.updateInformation
      : this.$apis.information.addInformation;
    (this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true

        this.$axios.post(url, this.formData
        ).then(res => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push('/information')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  destroyed () {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}
